// 获取code参数
export const getUrlCode = (_url: string): string => {
  if (_url.indexOf('code=') === -1) {
    return ''
  } else {
    const t = _url.match(/\?code=(\S*)&state=111/)
    const url = t && t.length && t[1]
    return unescape(url as string)
  }
}

/**
 * 获取url hash部分backurl value
 */
export const hashGetBackUrl = (): string => {
  const urlHash = window.location.hash
  if (urlHash.indexOf('backUrl=') === -1) {
    return ''
  } else {
    const t = urlHash.split('backUrl=')
    const url = t[t.length - 1]
    return unescape(url)
  }
}

// 火山埋点方法封装
export const userAction = (name: string, arg: any = {}): void => {
  if (process.env.VUE_APP_OPEN_USER_ACTION === 'true') {
    window.collectEvent(name, { ...arg })
    window.collectEvent('start')
  } else {
    // console.log('没有打开用户行为上报开关')
  }
}
